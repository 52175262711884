import React from "react";
import PropTypes from "prop-types";

const AccountItemBody = ({ title, element }) => {
  return (
    <div className="account-item-body">
      <div className="account-item-body-title">{title}</div>
      {element && <div className="account-item-body-element">{element}</div>}
    </div>
  );
};

AccountItemBody.propTypes = {
  title: PropTypes.node,
  element: PropTypes.node,
};

export default AccountItemBody;
